<template>
    <b-table
        :data="labels"
        :striped="true"
        :hoverable="true"
        :loading="isLoading"
        :mobile-cards="false"
        default-sort="id"
        checkable
        :checked-rows="selection"
        :row-class="getRowClass"
        @update:checkedRows="$emit('update:selection', $event)"
    >
        <b-table-column field="id" :visible="false" sortable/><!-- Hack for default sort -->
        <b-table-column field="created_at" label="Verzonden op" cell-class="is-vcentered" v-slot="props">
            <template v-if="props.row.first_tracked_at">
                {{ props.row.first_tracked_at|formatTimestamp('YYYY-MM-DD HH:mm') }}
            </template>
        </b-table-column>
        <b-table-column field="status" label="Status" cell-class="is-vcentered" v-slot="props">
            {{ props.row.status|formatStatus }}
            <span v-if="!props.row.is_active" class="tag is-danger">
                Verwijderd
            </span>
        </b-table-column>
        <b-table-column field="service_id" label="Vervoerder" cell-class="is-vcentered" v-slot="props">
            <v-with :courier="getCourier(props.row.service_id)" v-slot="{courier}">
                <div>
                    <template v-if="courier">
                        {{ courier.service_name }}
                    </template>
                    <b-skeleton :animated="true" :active="!courier"></b-skeleton>
                </div>
            </v-with>
        </b-table-column>
        <b-table-column field="shipment_type" label="Verzendmethode" cell-class="is-vcentered" v-slot="props">
            <v-with :courier="getCourier(props.row.service_id)" v-slot="{courier}">
                <div>
                    <template v-if="courier">
                        {{ getShipmentType(courier, props.row.shipment_type) }}
                    </template>
                    <b-skeleton :animated="true" :active="!courier"></b-skeleton>
                </div>
            </v-with>
            <span v-if="props.row.is_return" class="tag">
                Retour
            </span>
        </b-table-column>
        <b-table-column field="country" label="Land" cell-class="is-vcentered" v-slot="props">
            <country v-model="props.row.country"></country>
        </b-table-column>
        <b-table-column field="order_number" label="Ordernummer" cell-class="is-vcentered" v-slot="props">
            {{ props.row.order_number }}
        </b-table-column>
        <b-table-column field="tracking_number" label="Trackingnummer" cell-class="is-vcentered" v-slot="props">
            {{ props.row.tracking_number }}
        </b-table-column>
        <b-table-column field="price" label="Prijs" numeric cell-class="is-vcentered" v-slot="props">
            <v-with 
                :prices="getLabelInvoiceItems(props.row)"
                :total="getLabelInvoiceItemsTotal(props.row)"
                v-slot="{prices, total}"
            >
                <b-tooltip position="is-left" type="is-dark" multilined>
                    <div>{{ total|formatMoney }}</div>
                    <template v-slot:content>
                        <ul class="has-text-left">
                            <li v-for="item, idx in prices" :key="type + item.id + props.row.id + idx" class="is-flex is-justify-content-space-between">
                                <span>{{ item.description }}:</span>
                                <span>{{ item.unit_price|formatMoney }}</span>
                            </li>
                            <li class="has-text-weight-semibold is-flex is-justify-content-space-between">
                                <span>Totaal:</span>
                                <span>{{ total|formatMoney }}</span>
                            </li>
                        </ul>
                    </template>
                </b-tooltip>
            </v-with>
        </b-table-column>
        <b-table-column v-slot="props" cell-class="is-vcentered buttons is-right">
            <b-button
                v-if="props.row.invoice_items && props.row.invoice_items.some(i => i.type == type && i.invoice_id === false)"
                @click="$emit('toggle-label', {label: props.row, type: type, action: 'enable'})"
                size="is-small"
                aria-label="Facturatie aanzetten"
                icon-left="check"
            ></b-button>
            <b-button
                v-else
                @click="$emit('toggle-label', {label: props.row, type: type, action: 'disable'})"
                size="is-small"
                aria-label="Verwijderen uit facturatie"
                icon-left="trash"
            ></b-button>
        </b-table-column>

        <template #empty>
            <div class="has-text-centered">Geen ongefactureerde labels gevonden</div>
        </template>
    </b-table>
</template>

<script>
import { mapState } from 'vuex';
import Country from '@/components/Country';
export default {
    components: {Country},
    props: {
        labels: Array,
        selection: Array,
        loading: Boolean,
        type: String,
    },
    computed: {
        ...mapState({
            couriers: state => state.courier.all,
            isLoading(state) { return state.courier.isLoading || this.loading; },
        }),
    },
    methods: {
        getCourier(courierId) {
            return this.couriers.find(c => c.id == courierId);
        },
        getLabelInvoiceItems(label) {
            return label.invoice_items?.filter(i => i.type === this.type).map(i => i.parts).flat();
        },
        getLabelInvoiceItemsTotal(label) {
            return this.getLabelInvoiceItems(label)?.reduce((acc, value) => acc + parseFloat(value.unit_price), 0);
        },
        getShipmentType(courier, shipmentType) {
            const type = courier.shipment_types.find(t => t.id === shipmentType);
            return type?.name || 'Standaard';
        },
        getRowClass(row) {
            if (row?.invoice_items.some(i => i.type == this.type && i.invoice_id === false))
                return 'has-text-grey';
            return '';
        },
    },
    filters: {
        formatStatus(status) {
            const mapping = {
                'incomplete': 'Niet volledig',
                'ready': 'Klaar voor verwerken',
                'label': 'Niet geprint',
                'printed': 'Geprint',
                'transit': 'Onderweg naar sorteercentrum',
                'depot': 'In sorteercentrum',
                'courier': 'Chauffeur is onderweg',
                'delivered': 'Bezorgd',
            };

            const s = (new String(status)).toLowerCase();

            if (mapping[s])
                return mapping[s];
            else if (status)
                return status;
            return 'Onbekend';
        },
    },
};
</script>
