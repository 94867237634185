var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-table',{attrs:{"data":_vm.labels,"striped":true,"hoverable":true,"loading":_vm.isLoading,"mobile-cards":false,"default-sort":"id","checkable":"","checked-rows":_vm.selection,"row-class":_vm.getRowClass},on:{"update:checkedRows":function($event){return _vm.$emit('update:selection', $event)}},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('div',{staticClass:"has-text-centered"},[_vm._v("Geen ongefactureerde labels gevonden")])]},proxy:true}])},[_c('b-table-column',{attrs:{"field":"id","visible":false,"sortable":""}}),_c('b-table-column',{attrs:{"field":"created_at","label":"Verzonden op","cell-class":"is-vcentered"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [(props.row.first_tracked_at)?[_vm._v(" "+_vm._s(_vm._f("formatTimestamp")(props.row.first_tracked_at,'YYYY-MM-DD HH:mm'))+" ")]:_vm._e()]}}])}),_c('b-table-column',{attrs:{"field":"status","label":"Status","cell-class":"is-vcentered"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._v(" "+_vm._s(_vm._f("formatStatus")(props.row.status))+" "),(!props.row.is_active)?_c('span',{staticClass:"tag is-danger"},[_vm._v(" Verwijderd ")]):_vm._e()]}}])}),_c('b-table-column',{attrs:{"field":"service_id","label":"Vervoerder","cell-class":"is-vcentered"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('v-with',{attrs:{"courier":_vm.getCourier(props.row.service_id)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var courier = ref.courier;
return [_c('div',[(courier)?[_vm._v(" "+_vm._s(courier.service_name)+" ")]:_vm._e(),_c('b-skeleton',{attrs:{"animated":true,"active":!courier}})],2)]}}],null,true)})]}}])}),_c('b-table-column',{attrs:{"field":"shipment_type","label":"Verzendmethode","cell-class":"is-vcentered"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('v-with',{attrs:{"courier":_vm.getCourier(props.row.service_id)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var courier = ref.courier;
return [_c('div',[(courier)?[_vm._v(" "+_vm._s(_vm.getShipmentType(courier, props.row.shipment_type))+" ")]:_vm._e(),_c('b-skeleton',{attrs:{"animated":true,"active":!courier}})],2)]}}],null,true)}),(props.row.is_return)?_c('span',{staticClass:"tag"},[_vm._v(" Retour ")]):_vm._e()]}}])}),_c('b-table-column',{attrs:{"field":"country","label":"Land","cell-class":"is-vcentered"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('country',{model:{value:(props.row.country),callback:function ($$v) {_vm.$set(props.row, "country", $$v)},expression:"props.row.country"}})]}}])}),_c('b-table-column',{attrs:{"field":"order_number","label":"Ordernummer","cell-class":"is-vcentered"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._v(" "+_vm._s(props.row.order_number)+" ")]}}])}),_c('b-table-column',{attrs:{"field":"tracking_number","label":"Trackingnummer","cell-class":"is-vcentered"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_vm._v(" "+_vm._s(props.row.tracking_number)+" ")]}}])}),_c('b-table-column',{attrs:{"field":"price","label":"Prijs","numeric":"","cell-class":"is-vcentered"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('v-with',{attrs:{"prices":_vm.getLabelInvoiceItems(props.row),"total":_vm.getLabelInvoiceItemsTotal(props.row)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var prices = ref.prices;
var total = ref.total;
return [_c('b-tooltip',{attrs:{"position":"is-left","type":"is-dark","multilined":""},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('ul',{staticClass:"has-text-left"},[_vm._l((prices),function(item,idx){return _c('li',{key:_vm.type + item.id + props.row.id + idx,staticClass:"is-flex is-justify-content-space-between"},[_c('span',[_vm._v(_vm._s(item.description)+":")]),_c('span',[_vm._v(_vm._s(_vm._f("formatMoney")(item.unit_price)))])])}),_c('li',{staticClass:"has-text-weight-semibold is-flex is-justify-content-space-between"},[_c('span',[_vm._v("Totaal:")]),_c('span',[_vm._v(_vm._s(_vm._f("formatMoney")(total)))])])],2)]},proxy:true}],null,true)},[_c('div',[_vm._v(_vm._s(_vm._f("formatMoney")(total)))])])]}}],null,true)})]}}])}),_c('b-table-column',{attrs:{"cell-class":"is-vcentered buttons is-right"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [(props.row.invoice_items && props.row.invoice_items.some(function (i) { return i.type == _vm.type && i.invoice_id === false; }))?_c('b-button',{attrs:{"size":"is-small","aria-label":"Facturatie aanzetten","icon-left":"check"},on:{"click":function($event){return _vm.$emit('toggle-label', {label: props.row, type: _vm.type, action: 'enable'})}}}):_c('b-button',{attrs:{"size":"is-small","aria-label":"Verwijderen uit facturatie","icon-left":"trash"},on:{"click":function($event){return _vm.$emit('toggle-label', {label: props.row, type: _vm.type, action: 'disable'})}}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }